// src/DAppKitProvider.tsx
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { DAppKitUI } from "@vechain/dapp-kit-ui";
import { subscribeKey } from "valtio/utils";
import { jsx } from "react/jsx-runtime";
var Context = createContext(void 0);
var DAppKitProvider = ({
  children,
  nodeUrl,
  genesis,
  walletConnectOptions,
  usePersistence = false,
  customStyles,
  logLevel
}) => {
  const connex = useMemo(
    () => DAppKitUI.configure({
      nodeUrl,
      genesis,
      walletConnectOptions,
      usePersistence,
      logLevel,
      customStyles
    }),
    [
      nodeUrl,
      genesis,
      walletConnectOptions,
      usePersistence,
      logLevel,
      customStyles
    ]
  );
  const [account, setAccount] = useState(
    connex.wallet.state.address
  );
  const [source, setSource] = useState(
    connex.wallet.state.source
  );
  useEffect(() => {
    const addressSub = subscribeKey(
      connex.wallet.state,
      "address",
      (v) => setAccount(v)
    );
    const sourceSub = subscribeKey(
      connex.wallet.state,
      "source",
      (v) => setSource(v)
    );
    return () => {
      addressSub();
      sourceSub();
    };
  }, [connex.wallet.state]);
  const openModal = useCallback(() => {
    DAppKitUI.modal.open();
  }, []);
  const closeModal = useCallback(() => {
    DAppKitUI.modal.close();
  }, []);
  const context = useMemo(() => {
    return {
      connex: {
        thor: connex.thor,
        vendor: connex.vendor
      },
      wallet: {
        setSource: connex.wallet.setSource,
        disconnect: connex.wallet.disconnect,
        connect: connex.wallet.connect,
        availableWallets: connex.wallet.state.availableSources,
        account,
        source
      },
      modal: {
        open: openModal,
        close: closeModal
      }
    };
  }, [connex, account, source, closeModal, openModal]);
  return /* @__PURE__ */ jsx(Context.Provider, { value: context, children });
};
var useConnex = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('"useConnex" must be used within a ConnexProvider');
  }
  return context.connex;
};
var useWallet = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('"useWallet" must be used within a ConnexProvider');
  }
  return context.wallet;
};
var useWalletModal = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      '"useWalletModal" must be used within a ConnexProvider'
    );
  }
  return context.modal;
};

// assets/fonts/fonts.ts
import { createGlobalStyle } from "styled-components";
var fonts_default = createGlobalStyle`
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZs.woff) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZs.woff) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZs.woff) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZs.woff) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZs.woff) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZs.woff) format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB
    `;

// src/provider/ThemeProvider.tsx
import React2, { createContext as createContext2, useState as useState2 } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { jsx as jsx2 } from "react/jsx-runtime";
var defaultTheme = {
  mode: "LIGHT"
};
var ThemeContext = createContext2(
  void 0
);
var ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState2(defaultTheme);
  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => {
      if (prevTheme.mode === "LIGHT") {
        return { mode: "DARK" };
      }
      return { mode: "LIGHT" };
    });
  };
  return /* @__PURE__ */ jsx2(ThemeContext.Provider, { value: { theme: currentTheme, toggleTheme }, children: /* @__PURE__ */ jsx2(StyledThemeProvider, { theme: currentTheme, children }) });
};
var useThemeSelector = () => {
  const context = React2.useContext(ThemeContext);
  if (context === void 0) {
    throw new Error("useThemeSelector must be used within a ThemeProvider");
  }
  return context;
};

// src/Components/ConnectButtonWithModal/Wrapped/VechainDappConnectKitWrapped.tsx
import React3, { useMemo as useMemo2 } from "react";
import { createComponent } from "@lit/react";
import { VechainDappConnectKit as VechainDappConnectKitVanilla } from "@vechain/dapp-kit-ui";
import { jsx as jsx3 } from "react/jsx-runtime";
var VechainDappConnectKitWrapped = () => createComponent({
  tagName: "vwk-vechain-dapp-connect-kit",
  elementClass: VechainDappConnectKitVanilla,
  react: React3
});
var VechainDappConnectKitWithTheme = () => {
  const { theme } = useThemeSelector();
  const VechainDappConnectKit = useMemo2(
    () => VechainDappConnectKitWrapped(),
    []
  );
  return /* @__PURE__ */ jsx3(VechainDappConnectKit, { mode: theme.mode });
};

// src/Components/ConnectButtonWithModal/ConnectButtonWithModal.tsx
import { jsx as jsx4, jsxs } from "react/jsx-runtime";
var ConnectButtonWithModal = () => {
  return /* @__PURE__ */ jsxs(ThemeProvider, { children: [
    /* @__PURE__ */ jsx4(fonts_default, {}),
    /* @__PURE__ */ jsx4(VechainDappConnectKitWithTheme, {})
  ] });
};
export {
  ConnectButtonWithModal,
  DAppKitProvider,
  useConnex,
  useWallet,
  useWalletModal
};
