import React from "react";
import { DAppKitProvider } from '@vechain/dapp-kit-react';
import type { WalletConnectOptions } from '@vechain/dapp-kit';
import Authorize from './Authorize'
import { WALLET_CONNECT_PROJECT_ID } from './constants';

const walletConnectOptions: WalletConnectOptions = {
  projectId: WALLET_CONNECT_PROJECT_ID,
  metadata: {
    name: 'Sign In With Vechain',
    description: 'Sign in to your account using your Vechain wallet',
    url: window.location.origin,
    icons: [`https://vechain.energy/favicon.png`],
  },
};

export default function App() {
  const url = new URL(window.location.href)
  const network = url.searchParams.get('network') || 'main'

  return (
    <DAppKitProvider
      nodeUrl={`https://node-${network}net.vechain.energy`}
      genesis={network}
      usePersistence={false}
      walletConnectOptions={walletConnectOptions}
    >
      <Authorize />
    </DAppKitProvider>
  );
}