var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/dapp-kit.ts
import {
  DriverNoVendor,
  SimpleNet
} from "@vechain/connex-driver/dist/index.js";
import { Framework } from "@vechain/connex-framework";
import { blake2b256 } from "thor-devkit";

// src/genesis.ts
var genesisBlocks = {
  main: {
    number: 0,
    id: "0x00000000851caf3cfdb6e899cf5958bfb1ac3413d346d43539627e6be7ec1b4a",
    size: 170,
    parentID: "0xffffffff53616c757465202620526573706563742c20457468657265756d2100",
    timestamp: 1530316800,
    gasLimit: 1e7,
    beneficiary: "0x0000000000000000000000000000000000000000",
    gasUsed: 0,
    totalScore: 0,
    txsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
    txsFeatures: 0,
    stateRoot: "0x09bfdf9e24dd5cd5b63f3c1b5d58b97ff02ca0490214a021ed7d99b93867839c",
    receiptsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
    signer: "0x0000000000000000000000000000000000000000",
    isTrunk: true,
    transactions: []
  },
  test: {
    number: 0,
    id: "0x000000000b2bce3c70bc649a02749e8687721b09ed2e15997f466536b20bb127",
    size: 170,
    parentID: "0xffffffff00000000000000000000000000000000000000000000000000000000",
    timestamp: 1530014400,
    gasLimit: 1e7,
    beneficiary: "0x0000000000000000000000000000000000000000",
    gasUsed: 0,
    totalScore: 0,
    txsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
    txsFeatures: 0,
    stateRoot: "0x4ec3af0acbad1ae467ad569337d2fe8576fe303928d35b8cdd91de47e9ac84bb",
    receiptsRoot: "0x45b0cfc220ceec5b7c1c62c4d4193d38e4eba48e8815729ce75f9c0ab0e4c1c0",
    signer: "0x0000000000000000000000000000000000000000",
    isTrunk: true,
    transactions: []
  }
};
var normalizeGenesisId = (genesis) => {
  if (!genesis)
    return genesisBlocks.main.id;
  if (genesis === "main" || genesis === "test")
    return genesisBlocks[genesis].id;
  return genesis.id;
};
var normalizeGenesisBlock = (genesis) => {
  if (!genesis)
    return genesisBlocks.main;
  if (genesis === "main" || genesis === "test")
    return genesisBlocks[genesis];
  return genesis;
};

// src/wallet-manager.ts
import { proxy, subscribe } from "valtio";
import { subscribeKey } from "valtio/utils";

// src/create-wallet.ts
import { Connex } from "@vechain/connex";

// src/wallet-connect/signer.ts
import { getSdkError } from "@walletconnect/utils";

// src/wallet-connect/constants.ts
var DefaultMethods = /* @__PURE__ */ ((DefaultMethods2) => {
  DefaultMethods2["RequestTransaction"] = "thor_sendTransaction";
  DefaultMethods2["SignCertificate"] = "thor_signCertificate";
  return DefaultMethods2;
})(DefaultMethods || {});

// src/wallet-connect/signer.ts
var newWcSigner = ({
  genesisId,
  wcClient,
  web3Modal,
  onDisconnected
}) => {
  const chainId = `vechain:${genesisId.slice(-32)}`;
  let session;
  wcClient.get().then((clientInstance) => {
    listenToEvents(clientInstance);
    restoreSession(clientInstance);
  }).catch(() => {
    throw new Error(
      `Failed to initialise the wallet connect sign client`
    );
  });
  const makeRequest = (params, signer) => __async(void 0, null, function* () {
    const sessionTopic = yield getSessionTopic(signer);
    const signClient = yield wcClient.get();
    return signClient.request({
      topic: sessionTopic,
      chainId,
      request: params
    });
  });
  const signTx = (message, options) => __async(void 0, null, function* () {
    return makeRequest({
      method: "thor_sendTransaction" /* RequestTransaction */,
      params: [{ message, options }]
    });
  });
  const signCert = (message, options) => __async(void 0, null, function* () {
    return makeRequest({
      method: "thor_signCertificate" /* SignCertificate */,
      params: [{ message, options }]
    });
  });
  const disconnect = () => __async(void 0, null, function* () {
    if (!session)
      return;
    const topic = session.topic;
    session = void 0;
    const signClient = yield wcClient.get();
    try {
      yield signClient.disconnect({
        topic,
        reason: getSdkError("USER_DISCONNECTED")
      });
    } catch (e) {
      throw new Error(`SignClient.disconnect failed`);
    }
  });
  const validateSession = (requestedAddress) => {
    if (!session)
      return;
    const firstAccount = session.namespaces.vechain.accounts[0];
    const address = firstAccount.split(":")[2];
    const networkIdentifier = firstAccount.split(":")[1];
    if (networkIdentifier !== genesisId.slice(-32))
      return;
    if (requestedAddress && requestedAddress.toLowerCase() !== address.toLowerCase())
      return;
    return {
      address,
      networkIdentifier,
      topic: session.topic
    };
  };
  const getSessionTopic = (requestedAccount) => __async(void 0, null, function* () {
    const validation = validateSession(requestedAccount);
    if (validation)
      return validation.topic;
    const newSession = yield connect();
    return newSession.topic;
  });
  const connect = () => __async(void 0, null, function* () {
    const signClient = yield wcClient.get();
    const namespace = {
      methods: Object.values(DefaultMethods),
      chains: [chainId],
      events: []
    };
    try {
      const requiredNamespaces = {
        vechain: namespace
      };
      const res = yield signClient.connect({
        requiredNamespaces
      });
      if (res.uri) {
        yield web3Modal.openModal({ uri: res.uri });
      }
      return yield new Promise((resolve, reject) => {
        const endSubscription = web3Modal.subscribeModal((ev) => {
          if (!ev.open) {
            reject(new Error("User closed modal"));
            endSubscription();
          }
        });
        res.approval().then((newSession) => {
          session = newSession;
          endSubscription();
          resolve(newSession);
        }).catch(reject);
      });
    } finally {
      web3Modal.closeModal();
    }
  });
  const listenToEvents = (_client) => {
    _client.on("session_update", ({ topic, params }) => {
      const { namespaces } = params;
      const _session = _client.session.get(topic);
      session = __spreadProps(__spreadValues({}, _session), { namespaces });
    });
    _client.on("session_delete", () => {
      onDisconnected();
      disconnect().catch(() => {
        throw new Error("Failed to disconnect");
      });
    });
  };
  const restoreSession = (_client) => {
    if (typeof session !== "undefined")
      return;
    const sessionKeys = _client.session.keys;
    for (const key of sessionKeys) {
      const _session = _client.session.get(key);
      const accounts = _session.namespaces.vechain.accounts;
      for (const acc of accounts) {
        if (acc.split(":")[1] === genesisId.slice(-32)) {
          session = _session;
          return;
        }
      }
    }
  };
  const connectAccount = () => __async(void 0, null, function* () {
    if (!session) {
      session = yield connect();
    }
    const vechainNamespace = session.namespaces.vechain;
    if (!vechainNamespace) {
      throw new Error(
        "Failed to create a vechain session with wallet connect"
      );
    }
    const firstAccount = vechainNamespace.accounts[0];
    try {
      return firstAccount.split(":")[2];
    } catch (e) {
      throw new Error("Failed to get account from session");
    }
  });
  return {
    signTx,
    signCert,
    disconnect,
    genesisId,
    connect: connectAccount
  };
};

// src/wallet-connect/web3-modal.ts
import { WalletConnectModal } from "@walletconnect/modal";
var _cachedModals = {};
var genesisIds = [
  "0x00000000851caf3cfdb6e899cf5958bfb1ac3413d346d43539627e6be7ec1b4a",
  "0x000000000b2bce3c70bc649a02749e8687721b09ed2e15997f466536b20bb127"
];
var chains = genesisIds.map((id) => {
  return `vechain:${id.slice(-32)}`;
});
var createWcModal = (projectId) => {
  const cached = _cachedModals[projectId];
  if (cached) {
    return cached;
  }
  const modal = new WalletConnectModal({
    projectId,
    explorerRecommendedWalletIds: "NONE",
    mobileWallets: [
      {
        name: "VeWorld",
        id: "veworld-mobile",
        links: {
          native: "veworld://org.vechain.veworld.app/",
          universal: "https://veworld.com"
        }
      }
    ],
    chains,
    themeVariables: {
      "--wcm-z-index": "99999999"
    },
    walletImages: {
      "veworld-mobile": "https://www.veworld.net/assets/logo/logo.svg"
    }
  });
  _cachedModals[projectId] = modal;
  return modal;
};

// src/wallet-connect/client.ts
import { SignClient } from "@walletconnect/sign-client";
var _cachedClients = {};
var createWcClient = ({
  projectId,
  metadata
}) => {
  const cachedClient = _cachedClients[projectId];
  if (cachedClient) {
    return cachedClient;
  }
  const _signClient = SignClient.init({
    projectId,
    metadata
  });
  const client = {
    get: () => __async(void 0, null, function* () {
      try {
        return yield _signClient;
      } catch (e) {
        throw new Error(
          `Failed to initialise the wallet connect sign client`
        );
      }
    })
  };
  _cachedClients[projectId] = client;
  return client;
};

// src/wallets/certificate-wallet.ts
import { Certificate } from "thor-devkit";

// src/certificates.ts
var DEFAULT_CONNECT_CERT_MESSAGE = {
  purpose: "identification",
  payload: {
    type: "text",
    content: `The following dApp would like to see your public address: ${window.origin}`
  }
};
var DEFAULT_SIGN_IN_MESSAGE = {
  purpose: "identification",
  payload: {
    type: "text",
    content: `Sign in to: ${window.origin}`
  }
};

// src/wallets/certificate-wallet.ts
var CertificateBasedWallet = class {
  constructor(wallet) {
    this.wallet = wallet;
    this.connect = () => __async(this, null, function* () {
      const cert = DEFAULT_CONNECT_CERT_MESSAGE;
      const {
        annex: { domain, signer, timestamp },
        signature
      } = yield this.signCert(cert, {});
      try {
        Certificate.verify(__spreadProps(__spreadValues({}, cert), {
          signature,
          signer,
          domain,
          timestamp
        }));
        return {
          account: signer,
          verified: true
        };
      } catch (e) {
        return {
          account: signer,
          verified: false
        };
      }
    });
    this.signCert = (msg, options) => this.wallet.signCert(msg, options);
    this.signTx = (msg, options) => this.wallet.signTx(msg, options);
    this.disconnect = () => __async(this, null, function* () {
      var _a, _b;
      return (_b = (_a = this.wallet).disconnect) == null ? void 0 : _b.call(_a);
    });
  }
};

// src/wallets/wc-wallet.ts
var WCWallet = class {
  constructor(signer) {
    this.signer = signer;
    this.connect = () => __async(this, null, function* () {
      const account = yield this.signer.connect();
      return {
        account,
        verified: false
      };
    });
    this.signCert = (msg, options) => this.signer.signCert(msg, options);
    this.signTx = (msg, options) => this.signer.signTx(msg, options);
    this.disconnect = () => this.signer.disconnect();
  }
};

// src/utils/logger.ts
var LogLevel = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
};
var currentLogLevel = LogLevel.NONE;
var DAppKitLogger = {
  configure(level) {
    currentLogLevel = LogLevel[level];
  },
  debug(domain, context, ...args) {
    if (currentLogLevel >= LogLevel.DEBUG) {
      console.log(`[${domain}] (${context})`, ...args);
    }
  },
  info(domain, context, ...args) {
    if (currentLogLevel >= LogLevel.INFO) {
      console.info(`[${domain}] (${context})`, ...args);
    }
  },
  warn(domain, context, ...args) {
    if (currentLogLevel >= LogLevel.WARN) {
      console.warn(`[${domain}] (${context})`, ...args);
    }
  },
  error(domain, context, ...args) {
    if (currentLogLevel >= LogLevel.ERROR) {
      console.error(`[${domain}] (${context})`, ...args);
    }
  }
};

// src/vendor-signer.ts
var convertVendorToSigner = (vendor) => {
  return {
    signTx: (msg, options) => {
      const service = vendor.sign("tx", msg);
      if (options.gas) {
        service.gas(options.gas);
      }
      if (options.signer) {
        service.signer(options.signer);
      }
      if (options.dependsOn) {
        service.dependsOn(options.dependsOn);
      }
      if (options.link) {
        service.link(options.link);
      }
      if (options.comment) {
        service.comment(options.comment);
      }
      if (options.delegator) {
        service.delegate(
          options.delegator.url,
          options.delegator.signer
        );
      }
      if (options.onAccepted) {
        service.accepted(options.onAccepted);
      }
      DAppKitLogger.debug("vendor", "signTx", {
        messages: msg.length,
        options
      });
      return service.request();
    },
    signCert: (msg, options) => {
      const service = vendor.sign("cert", msg);
      if (options.signer) {
        service.signer(options.signer);
      }
      if (options.link) {
        service.link(options.link);
      }
      if (options.onAccepted) {
        service.accepted(options.onAccepted);
      }
      DAppKitLogger.debug("vendor", "signCert", {
        message: msg,
        options
      });
      return service.request();
    }
  };
};

// src/create-wallet.ts
var createWallet = ({
  source,
  genesis,
  walletConnectOptions,
  onDisconnected
}) => {
  const genesisId = normalizeGenesisId(genesis);
  DAppKitLogger.debug("createWallet", source);
  switch (source) {
    case "sync": {
      if (!window.connex) {
        throw new Error("User is not in a Sync wallet");
      }
      const vendor = new Connex.Vendor(genesisId, "sync");
      return new CertificateBasedWallet(convertVendorToSigner(vendor));
    }
    case "sync2": {
      const vendor = new Connex.Vendor(genesisId, "sync2");
      return new CertificateBasedWallet(convertVendorToSigner(vendor));
    }
    case "veworld": {
      if (!window.vechain) {
        throw new Error("VeWorld Extension is not installed");
      }
      const signer = window.vechain.newConnexSigner(genesisId);
      return new CertificateBasedWallet(signer);
    }
    case "wallet-connect": {
      if (!walletConnectOptions) {
        throw new Error("WalletConnect options are not provided");
      }
      const { projectId, metadata, modal } = walletConnectOptions;
      const wcClient = createWcClient({
        projectId,
        metadata
      });
      const web3Modal = modal != null ? modal : createWcModal(projectId);
      const wallet = newWcSigner({
        genesisId,
        wcClient,
        web3Modal,
        onDisconnected
      });
      return new WCWallet(wallet);
    }
  }
};

// src/wallet.ts
var DEFAULT_CONFIG = {
  requiresCertificate: true
};
var WalletMapping = {
  "wallet-connect": {
    requiresCertificate: false
  },
  veworld: DEFAULT_CONFIG,
  sync2: DEFAULT_CONFIG,
  sync: DEFAULT_CONFIG
};
var WalletSources = Object.keys(WalletMapping);
Object.freeze(WalletMapping);
Object.freeze(WalletSources);

// src/local-storage.ts
var STORAGE_PREFIX = "dappkit@vechain";
var WALLET_SOURCE_KEY = `${STORAGE_PREFIX}/source`;
var ACCOUNT_KEY = `${STORAGE_PREFIX}/account`;
var setSource = (source) => {
  DAppKitLogger.debug("LocalStorage", "setSource", source);
  if (!source) {
    localStorage.removeItem(WALLET_SOURCE_KEY);
  } else {
    localStorage.setItem(WALLET_SOURCE_KEY, source);
  }
};
var setAccount = (account) => {
  DAppKitLogger.debug("LocalStorage", "setAccount", account);
  if (!account) {
    localStorage.removeItem(ACCOUNT_KEY);
  } else {
    localStorage.setItem(ACCOUNT_KEY, account);
  }
};
var getSource = () => {
  const source = localStorage.getItem(WALLET_SOURCE_KEY);
  if (!source) {
    return null;
  }
  return source;
};
var getAccount = () => {
  const account = localStorage.getItem(ACCOUNT_KEY);
  if (!account) {
    return null;
  }
  return account;
};
var Storage = {
  getAccount,
  getSource,
  setAccount,
  setSource
};

// src/wallet-manager.ts
var WalletManager = class {
  constructor(options) {
    this.options = options;
    this.wallets = {};
    this.connect = () => this.wallet.connect().then((res) => {
      this.state.address = res.account;
      return res;
    }).catch((e) => {
      DAppKitLogger.error("WalletManager", "connect", e);
      throw e;
    });
    this.disconnect = (remote = false) => {
      if (!this.state.source) {
        return;
      }
      DAppKitLogger.debug(
        "WalletManager",
        "disconnect",
        "current source",
        this.state.source
      );
      const wallet = this.wallets[this.state.source];
      if (wallet && !remote && wallet.disconnect) {
        const res = wallet.disconnect();
        if (res instanceof Promise) {
          res.catch((e) => {
            DAppKitLogger.error("WalletManager", "disconnect", e);
          });
        }
      }
      this.state.source = null;
      this.state.address = null;
    };
    this.signTx = (msg, options) => this.wallet.signTx(msg, options).then((res) => {
      this.state.address = res.signer;
      return res;
    }).catch((e) => {
      DAppKitLogger.error("WalletManager", "signTx", e);
      throw e;
    });
    this.signCert = (msg, options) => this.wallet.signCert(msg, options).then((res) => {
      this.state.address = res.annex.signer;
      return res;
    }).catch((e) => {
      DAppKitLogger.error("WalletManager", "signCert", e);
      throw e;
    });
    this.setSource = (src) => {
      if (this.state.source === src) {
        return;
      }
      if (this.state.source && this.state.source !== src) {
        this.disconnect();
      }
      if (src === "wallet-connect" && !this.options.walletConnectOptions) {
        throw new Error("WalletConnect options are not provided");
      }
      if (src === "veworld" && !window.vechain) {
        throw new Error("VeWorld Extension is not installed");
      }
      if (src === "sync" && !window.connex) {
        throw new Error("User is not in a Sync wallet");
      }
      DAppKitLogger.debug("WalletManager", "setSource", src);
      this.disconnect();
      this.state.source = src;
    };
    this.subscribe = (listener) => {
      return subscribe(this.state, () => {
        listener(this.state);
      });
    };
    this.subscribeToKey = (key, listener) => {
      return subscribeKey(this.state, key, (value) => {
        listener(value);
      });
    };
    this.initState = (usePersistent) => {
      const availableSources = this.getAvailableSources();
      if (!usePersistent) {
        return proxy({
          source: null,
          address: null,
          availableSources
        });
      }
      const address = Storage.getAccount();
      const source = Storage.getSource();
      return proxy({
        source,
        address,
        availableSources
      });
    };
    this.initPersistence = (usePersistence) => {
      if (!usePersistence) {
        return;
      }
      this.subscribeToKey("address", Storage.setAccount);
      this.subscribeToKey("source", Storage.setSource);
    };
    this.getAvailableSources = () => {
      const wallets = ["sync2"];
      if (window.vechain) {
        wallets.push("veworld");
      }
      if (window.connex) {
        wallets.push("sync");
      }
      if (this.options.walletConnectOptions) {
        wallets.push("wallet-connect");
      }
      return wallets;
    };
    this.setFirstDetectedSource = () => {
      if (window.vechain) {
        this.setSource("veworld");
      } else if (this.options.walletConnectOptions) {
        this.setSource("wallet-connect");
      } else if (window.connex) {
        this.setSource("sync");
      } else {
        this.setSource("sync2");
      }
    };
    var _a, _b;
    this.state = this.initState((_a = options.usePersistence) != null ? _a : false);
    this.initPersistence((_b = options.usePersistence) != null ? _b : false);
    DAppKitLogger.debug("WalletManager", "constructor", this.state);
    if (options.useFirstDetectedSource) {
      this.setFirstDetectedSource();
    }
  }
  get wallet() {
    const source = this.state.source;
    DAppKitLogger.debug(
      "WalletManager",
      "get wallet",
      "current source",
      source
    );
    if (!source) {
      throw new Error("No wallet has been selected");
    }
    let wallet = this.wallets[source];
    if (!wallet) {
      if (!WalletSources.includes(source))
        throw new Error(`No wallet found for: ${source}`);
      DAppKitLogger.debug(
        "WalletManager",
        "get wallet",
        "creating a new wallet",
        source
      );
      const opts = __spreadProps(__spreadValues({}, this.options), {
        source,
        onDisconnected: () => this.disconnect(true)
      });
      wallet = createWallet(opts);
      this.wallets[source] = wallet;
    }
    return wallet;
  }
};

// src/dapp-kit.ts
var cache = {};
var createThorDriver = (node, genesis) => {
  const key = blake2b256(
    JSON.stringify({
      node,
      genesis
    })
  ).toString("hex");
  let driver = cache[key];
  if (!driver) {
    driver = new DriverNoVendor(new SimpleNet(node), genesis);
    cache[key] = driver;
  }
  return driver;
};
var DAppKit = class {
  constructor(options) {
    if (options.logLevel) {
      DAppKitLogger.configure(options.logLevel);
      DAppKitLogger.debug("DAppKit", "constructor", options);
    }
    const { nodeUrl, genesis } = options;
    const genesisBlock = normalizeGenesisBlock(genesis);
    const driver = createThorDriver(nodeUrl, genesisBlock);
    const walletManager = new WalletManager(options);
    driver.signTx = walletManager.signTx.bind(walletManager);
    driver.signCert = walletManager.signCert.bind(walletManager);
    const framework = new Framework(driver);
    this.thor = framework.thor;
    this.vendor = framework.vendor;
    this.wallet = walletManager;
  }
};
export {
  DAppKit,
  DAppKitLogger,
  WalletManager,
  WalletMapping,
  WalletSources,
  createWcClient,
  createWcModal,
  genesisBlocks,
  newWcSigner,
  normalizeGenesisBlock,
  normalizeGenesisId
};
